import { createGlobalStyle } from 'styled-components';
import backgroundImage from '../grid.png'
const GlobalStyles = createGlobalStyle`
   body {
     background: ${({ theme }) => theme.background};
     color: ${({ theme }) => theme.color};
     transition: all 0.50s linear; 
     z-index: 1;
     margin: 0;
     padding: 0;
     position: relative;
     min-height: 100vh;
    
    /* Ensure content is on top of the background */
    z-index: 1;
  }
   .secondaryColor {
      color: ${({theme}) => theme.secondaryColor}
  }

   body::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1; /* Ensure background is behind content */
    
    background-image: url(${backgroundImage}); 
    background-repeat: repeat;
    background-size: 50%;
    
    
    opacity: ${({theme}) => theme.backgroundOpacity}; /* Adjust opacity here (0 is fully transparent, 1 is fully opaque) */
  }

    .addLeftSpace {
      marginLeft: ${({theme}) => '200px'}
    }
  
`;

export default GlobalStyles;
