import React, { useState, useEffect, useContext } from 'react';
import Typewriter from 'typewriter-effect';
import Fade from 'react-reveal';
import endpoints from '../constants/endpoints';
import Social from './Social';
import FallbackSpinner from './FallbackSpinner';
import About from './About';
import Skills from './Skills';
import Education from './Education';
import { ThemeConsumer } from 'styled-components';
import styled, { ThemeContext } from 'styled-components';

const styles = {
  nameStyle: {
    fontSize: '5em',
  },
  inlineChild: {
    display: 'inline-block',
    color: '',
  },
  mainContainer: {
    paddingRight: '0px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '60vh', // full viewport height
    margin: '0 auto', // center horizontally
    marginTop: '20vh',
  },
  typeWriter: {
    color: 'white'
  }
};

function Home() {
  const [data, setData] = useState(null);
  const [logo, setLogo] = useState(null);
  const [typingEnabled, setTypingEnabled] = useState(true);
  const [displacement, setDisplacement] = useState({paddingLeft: 0, paddingRight: 0, pattingTop: 0, paddingBottom: 0})
  const [cursorPos, setCursorPos] = useState({x: 0, y:0, rot: 0, velocity: 0})
  const theme = useContext(ThemeContext);

  useEffect(() => {
    fetch(endpoints.home, {
      method: 'GET',
    })
      .then((res) => res.json())
      .then((res) => setData(res))
      .catch((err) => err);
  }, []);

  useEffect(() => {
    // Handler for mousemove event
    function updateFollower(event) {
      setCursorPos((prevPosition) => {
        const velocityMultiplier = 0.2;
        let diffX = event.clientX - prevPosition.x;
        return {x: event.clientX, y: event.clientY, rot: -diffX};
      });
    }

    // Attach the event listener to the window for global mouse tracking
    window.addEventListener('mousemove', updateFollower);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('mousemove', updateFollower);
    };
  }, []);

  useEffect(() => {
    fetch(endpoints.navbar, {
      method: 'GET',
    })
      .then((res) => res.json())
      .then((res) => setLogo(res))
      .catch((err) => err);
  }, []);

  function updateDisplacement(event) {
    const displacementMultiplier = 0.01;
    let centerX = window.outerWidth / 2;
    let centerY = window.innerWidth / 2;
    let xDisplacement = (centerX - event.clientX) * displacementMultiplier;
    let yDisplacement = (centerY - event.clientY) * displacementMultiplier;
    let left = (xDisplacement > 0)? xDisplacement : 0;
    let right = (xDisplacement < 0)? -xDisplacement : 0;
    let up = (yDisplacement > 0)? yDisplacement : 0;
    let down = (yDisplacement < 0)? -yDisplacement : 0;
    setDisplacement(prev => {
      return {paddingLeft: left, paddingRight: right, paddingBottom: down, paddingTop: up}});
  }

  return (
    <div onMouseMove={(event) => updateDisplacement(event)}>
    {/*<img 
      style={{
        transform: 'translate(-50%, -50%)', 
        position: 'absolute', 
        transform: `rotate(${cursorPos.rot}deg)`, 
        left: cursorPos.x, 
        top: cursorPos.y, 
        width: '3vh'}} 
        src={logo?.logo.source}/
        >
    */}
    {data ? (
    <Fade>
      <div style={{...styles.mainContainer, ...displacement}}>
        <h1 style={styles.nameStyle}>{data?.name}</h1>
        <div className="secondaryColor" style={{ flexDirection: 'row' }}>
          <h2 style={styles.inlineChild}>I&apos;m&nbsp;</h2>
          <Typewriter
            options={{
              loop: false,
              pauseFor: '0',
              cursor: "|"
            }}
            onInit={(typewriter) => {
              typewriter
                .changeDelay('natural')
                .typeString('a Developer.')
                .pauseFor(2000)
                .deleteAll()
                .changeDelay(50)
                .typeString('a Filmmaker?')
                .pauseFor(1000)
                .deleteAll()
                .changeDelay(100)
                .typeString('a Writer?')
                .pauseFor(2000)
                .changeDeleteSpeed(100)
                .deleteAll()
                .pauseFor(1000)
                .changeDelay(200)
                .typeString('a guy.')
                .callFunction(() => setTypingEnabled(false))
                .start()
            }}
          />
        </div>
        <Social />
      </div>
    </Fade>
  ) : <FallbackSpinner />}
  <About />
  <Skills />
  <Education style={{border: '1px solid red'}}/>
  </div>
  );
}

export default Home;
